export function registerMenuItem(item) {
  return {
    type: 'REGISTER_MENU_ITEM',
    payload: item,
  }
}

export function activateMenuItem(path) {
  return {
    type: 'ACTIVATE_MENU_ITEM',
    payload: path,
  }
}

export function removeMenuItem(item) {
  return {
    type: 'REMOVE_MENU_ITEM',
    payload: item,
  }
}
