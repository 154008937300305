import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Menu from './menu/menu'

const LIMIT = 50

const Header = () => {
  const [fixed, setFixed] = useState(false)

  useEffect(() => {
    function checkScroll() {
      if (window.scrollY > LIMIT && !fixed) {
        setFixed(true)
      }

      if (window.scrollY < LIMIT && fixed) {
        setFixed(false)
      }
    }
    window.addEventListener('scroll', checkScroll)

    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener('scroll', checkScroll)
    }
  })

  return (
    <header className={fixed ? 'fixed' : ''}>
      <div
        style={{
          margin: '0 auto',
          maxWidth: 960,
          padding: '1.45rem 1.0875rem',
        }}
      >
        <Menu />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteData: PropTypes.any,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
