import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Contact from '../components/contact/contact'
import Services from '../components/services/services'
import Vision from '../components/vision/vision'
import References from '../components/references/references'
import Hero from '../components/hero/hero'

import { Provider } from 'react-redux'
import configureStore from '../redux/store'

const store = configureStore()

const IndexPage = () => {
  return (
    <Layout customClass="home">
      <SEO title="Home" />
      <Hero />
      <Vision />
      <Services />
      <References />
      <Contact />
    </Layout>
  )
}

const App = () => (
  <Provider store={store}>
    <IndexPage />
  </Provider>
)

export default App
