import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer from './reducer'
import * as actions from './actions'

export function mapStateToProps(state) {
  const { items, active } = state.menu
  return {
    menuItems: items,
    active,
  }
}

export function mapDispatchToProps(dispatch) {
  return {
    register: (item) => dispatch(actions.registerMenuItem(item)),
    activate: (item) => dispatch(actions.activateMenuItem(item)),
  }
}

export default function configureStore(preloadedState = {}) {
  const middlewares = [thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  return store
}
