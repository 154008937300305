/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.scss'
import icon from '../images/sunsets-icon.svg'

const Layout = ({ children, customClass }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={customClass}>
      <Header siteData={data} siteTitle={data.site.siteMetadata.title} />
      <div className="main-wrapper">
        {children}
      </div>
      <div
        style={{
          margin: '0 auto',
          maxWidth: 960,
          padding: '0 1.0875rem 1.45rem',
        }}
      >
        <footer>
          © <strong className="font-weight-bold"> 2011 - {new Date().getFullYear()} </strong> by Sunsets Vision s.r.o. All rights reserved.
          <img src={icon} width="50" style={{ marginLeft: '2em'}} />
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  customClass: PropTypes.any,
}

export default Layout
