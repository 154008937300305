import React, { useState } from 'react'
import * as EmailValidator from 'email-validator'
import axios from 'axios'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

function submitForm(Email, Content) {
  // TODO call the mailgun api
  return new Promise((resolve, reject) => {
    setTimeout( function() {
      return axios
        .post('//api.sunsets.cz/messages', { Email, Content })
        .then((data) => {
          resolve(data)
        })
        .catch(function (error) {
          reject(error)
        })
    }, 1500)
  })
}

export const ContactForm = () => {
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')
  const [sent, setSent] = useState(false)
  const [valid, setValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const validate = () => {
    EmailValidator.validate(email)
      ? setValid(true)
      : setValid(false)
  }

  const reset = () => {
    setMessage('')
    setEmail('')
    setSent(false)
    setValid(false)
    setLoading(false)
  }

  const handleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setError(null)

    if (sent) {
      reset()
      return
    }

    if (!valid) {
      setError('Please enter valid email address')
      return
    }

    try {
      setLoading(true)
      submitForm(email, message)
        .then(() => {
          setSent(true)
          setValid(false)
          setLoading(false)
          // setEmail('')
          // setMessage('')
        })
        .catch(e => {
          console.error(e)
          setError('Failed to send the message')
          // setValid(false)
          setLoading(false)
        })
    } catch (e) {
      console.error(e)
      setError(e)
      setLoading(false)
    }
  }

  return (
    <Form noValidate validated={valid}  autoComplete="off">
      <Form.Group controlId="exampleForm.ControlInput1">
        <Form.Label>Leave us a message</Form.Label>
        <Form.Control
          required
          disabled={sent || loading}
          isValid={valid}
          onBlur={e => validate(e)}
          onFocus={() => setError(null)}
          onChange={e => setEmail(e.target.value)}
          type="email"
          placeholder="your@address.com"
          value={email}
        />
      </Form.Group>
      <Form.Group controlId="exampleForm.ControlTextarea1">
        <Form.Control
          required
          disabled={sent || loading}
          onChange={e => setMessage(e.target.value)}
          as="textarea"
          placeholder="Your message"
          rows="3"
          value={message}
        />
      </Form.Group>
      <Button
        onClick={!loading ? handleClick : null}
        variant="outline-secondary"
        type="submit"
      >
        {!loading ? !sent ? 'Send message' : 'Send another' : 'Sending'}
      </Button>
      {loading &&
        <span className="feedback">
          <Spinner as="span" animation="border" variant="secondary" />
        </span>
      }
      {sent &&
        <span className="feedback">Your message has been sent.</span>
      }
      {error &&
        <span className="feedback">{error}</span>
      }
    </Form>
  )
}
