import React from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import { connect } from 'react-redux'
import Nav from 'react-bootstrap/Nav'
import { mapStateToProps, mapDispatchToProps } from '../../redux/store'

import icon from '../../images/sunsets-icon.svg'
import './menu.scss'

gsap.registerPlugin(ScrollToPlugin)

const scrollToRef = (ref) => {
  const targetPosition = ref.offsetTop - 120

  gsap.to(window, { duration: 1, scrollTo: targetPosition })
}

export const Menu = (props) => {
  const handleClick = path => e => {
    e.preventDefault()

    props.activate(path)
    const target = props.menuItems.find(i => i.path === path)

    scrollToRef(target.anchor)
  }

  const itemsToRender = props.menuItems || []

  return (
    <>
      <Nav variant="dark" className="justify-content-center" activeKey="/home">
        <Nav.Item>
          <Nav.Link href="/">
            <img className="header-logo" width="60" height="60" src={icon} alt=""/>
          </Nav.Link>
        </Nav.Item>
        {itemsToRender.map((item, i) => {
          return (
            <Nav.Item key={i}>
              <Nav.Link href={item.path} onClick={handleClick(item.path)}>{item.title}</Nav.Link>
            </Nav.Item>
          )
        })}
      </Nav>
    </>
  )
}

Menu.propTypes = {
  menuItems: PropTypes.array,
  activate: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
