import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../../redux/store'

import { Container, Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { IoIosArrowDown } from 'react-icons/io'

import { Logo } from '../logo'
import { Anchor } from '../anchor/anchor'

// import bg from '../../images/sunset-star.png'
import bg from '../../images/sunset-mountains-embed.svg'
// import bg from '../../images/sunset-custom.jpg'

export const Hero = (props) => {
  const anchorRef = React.createRef()
  const [ registered, setRegistered ] = useState(false)

  useEffect(() => {
    if (!registered && anchorRef.current) {
      setRegistered(true)
      props.register && props.register({
        title: 'Home',
        path: '/',
        anchor: anchorRef.current,
      })
    }
  }, [registered])

  return (
    <Anchor ref={anchorRef}>
      <div
        className="main-bg hero-content"
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url(${bg})`,
          backgroundPosition: 'center center',
        }}
      >
        <div className="logo-wrapper">
          <Logo width="420" alt="Sunsets Vision" />
          <h1>DIGITAL SOLUTIONS</h1>
          <p>Complex software and digital media solutions</p>
          <div className="button-wrapper">
            <Button variant="outline-secondary">CONTACT US</Button>
          </div>
        </div>
        <Container>
          <Row>
            <Col>
              <div className="jumper-wrapper">
                <IoIosArrowDown className="jumper" size="2em" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Anchor>
  )
}

Hero.propTypes = {
  register: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Hero)
