import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../../redux/store'

import { Container, Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { FaHtml5, FaDollarSign, FaVideo, FaReact, FaTv, FaServer } from 'react-icons/fa'
import { IoIosArrowBack } from 'react-icons/io'

import { Anchor } from '../anchor/anchor'

const title = 'Services'

export const Services = (props) => {
  const anchorRef = React.createRef()
  const [ registered, setRegistered ] = useState(false)

  useEffect(() => {
    if (!registered && anchorRef.current) {
      setRegistered(true)
      props.register && props.register({
        title,
        path: `/${title.toLowerCase()}`,
        anchor: anchorRef.current,
      })
    }
  }, [registered])

  return (
    <>
      <Anchor ref={anchorRef}>
        <h2>{title}</h2>
      </Anchor>
      <div className="block-content services">
        <Container fluid>
          <Row>
            <Col xs={12} md={4}>
              <div className="header">
                <FaReact size="5em" />
                <h5 className="font-weight-bold my-4">Complex Webapps</h5>
                <p className="grey-text mb-0">We build web applications of various complexities and scales.</p>
                <Button variant="outline-primary"><IoIosArrowBack /> detail</Button>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="header">
                <FaHtml5 size="5em" />
                <h5 className="font-weight-bold my-4">Webdesing</h5>
                <p className="grey-text mb-0">Accessible web presentation of any size with beautiful desing.</p>
                <Button variant="outline-primary"><IoIosArrowBack /> detail</Button>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="header">
                <FaVideo size="5em" />
                <h5 className="font-weight-bold my-4">Video Streaming</h5>
                <p className="grey-text mb-0">OTT delivery of video with adaptive streaming and DRM protection.</p>
                <Button variant="outline-primary"><IoIosArrowBack /> detail</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <div className="header">
                <FaDollarSign size="5em" />
                <h5 className="font-weight-bold my-4">Online Advertising</h5>
                <p className="grey-text mb-0">Integration of all online advertising tools to your digital platforms.</p>
                <Button variant="outline-primary"><IoIosArrowBack /> detail</Button>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="header">
                <FaTv size="5em" />
                <h5 className="font-weight-bold my-4">Smart TVs</h5>
                <p className="grey-text mb-0">Applications for smart TVs for sreaming VOD and Live content.</p>
                <Button variant="outline-primary"><IoIosArrowBack /> detail</Button>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="header">
                <FaServer size="5em" />
                <h5 className="font-weight-bold my-4">Scalable hosting</h5>
                <p className="grey-text mb-0">Deliver your platfomrs at any scale world-wide.</p>
                <Button variant="outline-primary"><IoIosArrowBack /> detail</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
            </Col>
            <Col xs={12} md={4}>
              <div className="text-center">
                <Button variant="outline-secondary">CHECK MORE</Button>
              </div>
            </Col>
            <Col xs={12} md={4}>

            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

Services.propTypes = {
  register: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Services)
