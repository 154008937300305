import React from 'react'
import PropTypes from 'prop-types'

import logo from '../images/sunsets-logo-sh-gold.svg'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

export const Logo = ({ width }) => {
  return <img width={width ? width : 'auto' } className="main-logo" src={logo} alt="Sunsets Vision" />
}

Logo.propTypes = {
  width: PropTypes.string,
}

export default Logo
