import { combineReducers } from 'redux'

const initialMenuState = {
  active: null,
  items: [],
}

export const menu = (state = initialMenuState, action) => {
  switch (action.type) {
    case 'REGISTER_MENU_ITEM':
      return {
        ...state,
        items: [
          ...state.items,
          action.payload,
        ]
      }
    case 'ACTIVATE_MENU_ITEM':
      return {
        ...state,
        active: action.payload,
      }
    case 'REMOVE_MENU_ITEM':
      return {
        ...state,
        items: state.items.filter(i => i === action.item)
      }
    default:
      return state
  }
}

export default combineReducers({ menu })
