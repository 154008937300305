import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../../redux/store'

import { Container, Row, Col } from 'react-bootstrap'
import { FaPhone } from 'react-icons/fa'
import { IoIosMail } from 'react-icons/io'

import { Anchor } from '../anchor/anchor'
import { ContactForm } from '../contact/form'

import map from '../../images/map.png'
import pin from '../../images/pin.svg'

const Address = () => (
  <address>
    <Container>
      <Row>
        <Col>
          <h4>
            <strong className="font-weight-bold">Sunsets Vision s.r.o.</strong>
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          Tržiště 372/1<br />
          118 00 Praha 1 – Malá Strana<br />
          CZECH REPUBLIC<br />
        </Col>
      </Row>
      <Row>
        <Col>
          <br />
          <span className="pad"><strong className="font-weight-bold">IČ</strong></span> 24135381<br />
          <span className="pad"><strong className="font-weight-bold">VAT</strong></span> CZ24135381<br />
        </Col>
        <Col>
          <br />
          <span className="pad"><strong className="font-weight-bold"><FaPhone /></strong></span> +420 601 384 788<br />
          <span className="pad"><strong className="font-weight-bold"><IoIosMail /></strong></span><a href="mailto:contact@sunsets.cz">contact@sunsets.cz</a>
        </Col>
      </Row>
      <Row>
        <Col>
          <br />
          <h6>PRAGUE OFFICE</h6>
          Nerudova 219/32<br />
          118 00 Praha 1<br />
          CZECH REPUBLIC<br />
        </Col>
        <Col>
          <br />
          <h6>VIENNA OFFICE</h6>
          Thaliastraße 2/8<br />
          1160 Vienna<br />
          AUSTRIA<br />
        </Col>
      </Row>
    </Container>
  </address>
)

export const Contact = (props) => {
  const anchorRef = React.createRef()
  const [ registered, setRegistered ] = useState(false)

  useEffect(() => {
    if (!registered && anchorRef.current) {
      setRegistered(true)
      props.register({
        title: 'Contact',
        path: '/contact',
        anchor: anchorRef.current,
      })
    }
  }, [registered])

  return (
    <div className="block-contact">
      <Anchor ref={anchorRef}>
        <h2>Contact</h2>
      </Anchor>
      <div className="block-content">
        <Container fluid>
          <Row>
            <Col sm={12} md={6}>
              <Address />
            </Col>
            <Col sm={12} md={6}>
              <div style={{ clear: 'both', margin: '0' }}>
                <ContactForm />
              </div>
            </Col>
          </Row>
          <Row>
          </Row>
        </Container>
      </div>
      <div className="map" style={{ width: '100%', height: '300px', background: `url(${map})`, backgroundSize: 'cover', backgroundPosition: 'center center'}}>
        <img className="pin jumper" src={pin} alt=""/>
      </div>
    </div>
  )
}

Contact.propTypes = {
  register: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
