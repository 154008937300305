import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'


import { mapStateToProps, mapDispatchToProps } from '../../redux/store'
import { Button, Container, Row, Col } from 'react-bootstrap'
import { Anchor } from '../anchor/anchor'

import vojta from '../../images/vojta.jpg'

const Vision = (props) => {
  const anchorRef = React.createRef()
  const [ registered, setRegistered ] = useState(false)

  useEffect(() => {
    if (!registered && anchorRef.current) {
      setRegistered(true)
      props.register && props.register({
        title: 'The Vision',
        path: '/vision',
        anchor: anchorRef.current,
      })
    }
  }, [registered])

  return (
    <div className="block-content">
      <Anchor ref={anchorRef}>
        <h2>The Sunsets Vision</h2>
      </Anchor>
      <Container fluid>
        <Row>
          <Col>
            <p className="lead">
              With all of our project we focus on these main goals. We only
              deliver top quality products and services that scale well. <em>„There
              is nothing we can&apos;t do“</em> attitude is running in our veins.
              We alway push to use cutting edge technology and bring innovation
              forward in every project we do. Last but not least we love beautiful
              design which is one of the main pillars to success and draw attention.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={7}>
            <div className="person" style={{ textAlign: 'right' }}>
              <p className="lead">
                With the advent of the 21st century, we are facing huge transformation
                into a digital era and we are here to help you get prepared for it.
              </p>
            </div>
          </Col>
          <Col xs={5}>
            <div className="person-wrapper" style={{ marginTop: '0.7em' }}>
              <img src={vojta} width="100" alt="Maps" style={{ borderRadius: '50%', marginRight: '1em', float: 'left' }} />
              <span className="person" style={{ textAlign: 'left' }}>
                <h3>Vojtech Zalesky</h3>
                <span><em>– Founder</em></span>
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ textAlign: 'center' }}>
              <Button variant="outline-secondary">READ MORE</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

Vision.propTypes = {
  register: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Vision)
