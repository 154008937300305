import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../../redux/store'

import { Anchor } from '../anchor/anchor'
import Slider from 'react-slick'

import bitmovin from '../../images/references/bitmovin.png'
import iflix from '../../images/references/iflix.png'
import nova from '../../images/references/nova.png'
import cra from '../../images/references/cra.png'
import ods from '../../images/references/ods.png'
import ms from '../../images/references/mediaservis.png'
import etnetera from '../../images/references/etnetera.png'
import apaa from '../../images/references/apaa.svg'
import nie from '../../images/references/nie.png'
import yoon from '../../images/references/yoon.png'
import commlab from '../../images/references/commlab.svg'

const title = 'References'
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 2200,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

export const References = (props) => {
  const anchorRef = React.createRef()
  const [ registered, setRegistered ] = useState(false)

  useEffect(() => {
    if (!registered && anchorRef.current) {
      setRegistered(true)
      props.register && props.register({
        title,
        path: `/${title.toLowerCase()}`,
        anchor: anchorRef.current,
      })
    }
  }, [registered])

  return (
    <>
      <Anchor ref={anchorRef}>
        <h2>{title}</h2>
      </Anchor>
      <div className="main-carousel">
        <Slider {...sliderSettings}>
          <div><img width="200" src={bitmovin} alt="Bitmovin"/></div>
          <div><img width="200" src={nova} alt="TV Nova"/></div>
          <div><img width="200" src={cra} alt="Ceske radiokomunikace"/></div>
          <div><img width="200" src={iflix} alt="iflix"/></div>
          <div><img width="200" src={etnetera} alt="Etnetera"/></div>
          <div><img width="200" src={ods} alt="ODS"/></div>
          <div><img width="200" src={ms} alt="Media Servis"/></div>
          <div><img width="200" src={nie} alt="NIE Theatre"/></div>
          <div><img width="200" src={apaa} alt="Apaa"/></div>
          <div><img width="200" src={commlab} alt="Communications Lab"/></div>
          <div><img width="200" src={yoon} alt="Yoon"/></div>
        </Slider>
      </div>
    </>
  )
}

References.propTypes = {
  register: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(References)
