import React from 'react'
import PropTypes from 'prop-types'

export const Anchor = React.forwardRef(({ children }, ref) => (
  <div ref={ref} className="anchor">
    {children}
  </div>
))

Anchor.displayName = 'Anchor'

Anchor.propTypes = {
  children: PropTypes.any,
}
